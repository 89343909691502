import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Slider, Switch, Typography} from "@mui/material";
import {useGoogleLogin} from "@react-oauth/google";
import {addCompanyIntegration, deleteCompanyIntegration, getCompanyIntegrations} from "./integrations.service";
import {useNavigate} from "react-router-dom";
import {AddCompanyIntegrationDto, CompanyIntegrationDto} from "./dtos/integration.dto";
import {enqueueSnackbar} from "notistack";
import {logError} from "../../shared/services/logger.service";
import {useUserSettings} from "../../components/UserSettingsContext";

const integrationDisplayNameTypeMap: Record<string, string> = {
  'Gmail': 'google',
}

const supportedIntegrationNames = Object.keys(integrationDisplayNameTypeMap);

export const SettingsPage = () => {
  const navigate = useNavigate();
  const {settings, updateSettings} = useUserSettings();
  const [companyIntegrations, setCompanyIntegrations] = useState<CompanyIntegrationDto[]>([]);
  const [loadingIntegrationName, setLoadingIntegrationName] = useState<string | undefined>(undefined);

  const convertImageQualitySettingsToSliderValue = (imageQuality: number) => {
    return imageQuality * 10;
  }
  const [imageQuality, setImageQuality] = useState<number>(convertImageQualitySettingsToSliderValue(settings.imageUploadQuality));

  const refreshCompanyIntegrations = async () => {
    try {
      const integrations = await getCompanyIntegrations(navigate);
      setCompanyIntegrations(integrations);
    } catch (error) {
      console.error('Error fetching integrations:', error);
      enqueueSnackbar(`Error fetching integrations: ${error}`, {variant: 'error', autoHideDuration: 5000});
    }
  }
  useEffect(() => {
    refreshCompanyIntegrations();
  }, []);

  const convertSliderValueToImageQualitySettings = (sliderValue: number) => {
    return sliderValue / 10;
  }
  useEffect(() => {
    const imageUploadQuality = convertSliderValueToImageQualitySettings(imageQuality);
    updateSettings({imageUploadQuality});
  }, [imageQuality]);

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async credentialResponse => {
      try {
        const integration = {
          integrationType: 'google',
          authType: 'oauth2',
          authCode: credentialResponse.code,
        }
        await addCompanyIntegration(navigate, integration);
        await refreshCompanyIntegrations();
      } catch (error) {
        logError('Adding integration failed Failed:', error as Error);
        enqueueSnackbar(`Error adding integration: ${error}`, {variant: 'error', autoHideDuration: 5000})
      } finally {
        setLoadingIntegrationName(undefined);
      }
    },
    onError: error => {
      logError('Google OAuth Login Failed:', error);
      enqueueSnackbar(`Error adding integration: ${error}`, {variant: 'error', autoHideDuration: 5000})
      setLoadingIntegrationName(undefined);
    },
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.send'
  });

  const integrationEnabled = (displayName: string) => {
    const integrationType = integrationDisplayNameTypeMap[displayName];
    return companyIntegrations.some(integration => integration.integrationType === integrationType);
  }

  const handleConnectGmail = async () => {
    setLoadingIntegrationName('Gmail');
    try {
      login();
    } catch (error) {
      logError('Login Failed:', error as Error);
      enqueueSnackbar(`Error adding integration: ${error}`, {variant: 'error', autoHideDuration: 5000})
      setLoadingIntegrationName(undefined);
    }
  }

  const handleDeleteIntegrationByDisplayName = async (name: string) => {
    const integration = companyIntegrations.find(integration => integration.integrationType === integrationDisplayNameTypeMap[name]);
    if(!integration) {
      logError('Integration not found', {name});
      enqueueSnackbar(`Integration not found`, {variant: 'error', autoHideDuration: 5000});
    }
    try {
      await deleteCompanyIntegration(navigate, integration!.id);
      await refreshCompanyIntegrations();
    } catch (e) {
      logError('Error deleting integration', {name}, e as Error);
      enqueueSnackbar(`Error deleting integration: ${e}`, {variant: 'error', autoHideDuration: 5000});
    }
  }

  return (
    <Paper sx={{display: 'flex', flexDirection: 'column',  width: '100%', height: '100%', padding: 3}}>
      <Box sx={{ width: '100%', maxWidth: 360, margin: '0 auto', padding: '2rem' }}>
        <Typography variant="h4" component="div" textAlign={'center'} gutterBottom>
          Images
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', width: '100%'}}>
          <Typography variant="h6" component="div" textAlign={'center'}/>
          <Typography variant="body1" component="div" textAlign={'center'}>
            Uploaded Image Quality
          </Typography>
          <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <Typography variant="body2" sx={{marginRight: 2}}>Faster Uploads</Typography>
            <Slider
              sx={{flexGrow: 1}}
              value={imageQuality}
              onChange={(event, newValue) => setImageQuality(newValue as number)}
              aria-labelledby="image-quality-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={2}
              max={10}
            />
            <Typography variant="body2" sx={{marginLeft: 2}}>Higher Quality</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%', maxWidth: 360, margin: '0 auto', padding: '2rem' }}>
        <Typography variant="h4" component="div" textAlign={'center'} gutterBottom>
          Integrations
        </Typography>
        <List>
          {supportedIntegrationNames.map((integrationName, index) => (
            <ListItem key={integrationName}>
              <ListItemText primary={integrationName} />
              <ListItemSecondaryAction>
                {loadingIntegrationName === integrationName ?
                  <CircularProgress/> :
                  integrationEnabled(integrationName)
                    ? <Button variant={'contained'} onClick={() => handleDeleteIntegrationByDisplayName(integrationName)}>Disconnect</Button>
                    : <Button variant={'contained'} onClick={() => handleConnectGmail()}>Connect</Button>
                }
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
}