import React, {ReactNode, useEffect} from 'react';
import {BrowserRouter, Link, Outlet, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import NoPagePage from "./pages/no-page/NoPagePage";
import LoginCallbackPage from "./pages/login/LoginCallbackPage";
import LogoutPage from "./pages/logout/LogoutPage";
import LoginPage from "./pages/login/LoginPage";
import './App.css';
import {blue, green} from "@mui/material/colors";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import AuthGuard from "./components/AuthGuard";
import EstimatePage from "./pages/estimate/EstimatePage";
import { SnackbarProvider } from 'notistack';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import Layout from "./components/Layout";
import {ToolbarProvider} from "./components/ToolbarContext";
import EstimateItemizedPage from "./pages/estimate-itemized/EstimateItemizedPage";
import EstimateListPage from "./pages/estimates/EstimateListPage";
import ClientListPage from "./pages/client/ClientListPage";
import AppointmentListPage from "./pages/appointment/AppointmentListPage";
import AppointmentMapPage from "./pages/appointments-map/AppointmentMapPage";
import WorkOrderListPage from "./pages/work-order/WorkOrderListPage";
import InvoiceListPage from "./pages/invoice/InvoiceListPage";
import EquipmentListPage from "./pages/equipment/EquipmentListPage";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {SettingsPage} from "./pages/settings/SettingsPage";
import CreateNewInvoiceListPage from "./pages/invoice/CreateNewInvoiceListPage";
import {LocationPermissionsProvider} from "./components/LocationPermissionsContext";
import EstimateAutomaticFollowUpsPage from "./pages/estimates/EstimateAutomaticFollowUpsPage";
import GoogleEarthPage from "./pages/google-earth-experimentation/GoogleEarthPage";
import DeviceIMUData from "./pages/experiments/DeviceIMUData";
import SignalRTestPage from "./pages/signalr-test/SignalRTestPage";
import {RealTimeProvider} from "./shared/providers/real-time.provider";
import Redirect from "./shared/misc/Redirect";
import {UserSettingsProvider} from "./components/UserSettingsContext";

function App() {
  useEffect(() => {
    document.title = 'SavaQuan';

    if (!(window as any)?.DD_RUM?.getInternalContext()?.session_id) {
      datadogRum.init({
        applicationId: 'f848c0cb-d037-453a-8c38-c716f69c8fb0',
        clientToken: 'pubd3ad6f80ded8b792a804b56b81f8d45c',
        site: 'us5.datadoghq.com',
        service: 'accusite-ui',
        env: process.env.REACT_APP_DD_ENV,
        version: process.env.REACT_APP_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      });
    }

    if (!(window as any)?.DD_LOGS?.getInternalContext()?.session_id) {
      datadogLogs.init({
        clientToken: 'pubd3ad6f80ded8b792a804b56b81f8d45c',
        site: 'us5.datadoghq.com',
        service: 'accusite-ui',
        env: process.env.REACT_APP_DD_ENV,
        version: process.env.REACT_APP_VERSION,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
      });
    }
  }, []);
  const theme = createTheme({
    palette: {
      primary: {
        main: green[500],
      },
      secondary: {
        main: blue[500],
      },
      mode: 'dark',
    },
  });

  const AuthenticatedUserLayout = () => {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <SnackbarProvider/>
      <RealTimeProvider>
      <ToolbarProvider>
      <LocationPermissionsProvider>
      <GoogleOAuthProvider clientId="191352312973-5vhn9rudf8l1q4rci7e8jpoiaau8bktc.apps.googleusercontent.com">
      <UserSettingsProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route element={<AuthenticatedUserLayout/>}>
                <Route path="" element={<AuthGuard><HomePage/></AuthGuard>} />
                {/*<Route path="signalr-test" element={<AuthGuard><SignalRTestPage/></AuthGuard>} />*/}
                {/*<Route path="maps-test" element={<AuthGuard><GoogleEarthPage/></AuthGuard>} />*/}
                {/*<Route path="device-imu-data" element={<AuthGuard><DeviceIMUData/></AuthGuard>} />*/}
                <Route path="measure-tree" element={<Redirect url={'https://play.google.com/store/apps/details?id=com.accusite.measure'}/>}/>
                <Route path="appointments" element={<AuthGuard><AppointmentListPage/></AuthGuard>} />
                <Route path="appointments/map" element={<AuthGuard><AppointmentMapPage/></AuthGuard>} />
                <Route path="clients" element={<AuthGuard><ClientListPage/></AuthGuard>} />
                <Route path="estimates">
                  <Route path=":estimateId">
                    <Route path="itemized" element={<AuthGuard><EstimateItemizedPage/></AuthGuard>} />
                    <Route path="" element={<AuthGuard><EstimatePage/></AuthGuard>} />
                  </Route>
                  <Route path="automatic-follow-ups"  element={<AuthGuard><EstimateAutomaticFollowUpsPage/></AuthGuard>}/>
                  <Route path="" element={<AuthGuard><EstimateListPage/></AuthGuard>}/>
                </Route>
                <Route path="equipment" element={<AuthGuard><EquipmentListPage/></AuthGuard>} />
                <Route path="invoices">
                  <Route path="" element={<AuthGuard><InvoiceListPage/></AuthGuard>} />
                  <Route path="create-new" element={<AuthGuard><CreateNewInvoiceListPage/></AuthGuard>} />
                  <Route path=":invoiceId" element={<AuthGuard><EstimateItemizedPage/></AuthGuard>}/>
                </Route>
                <Route path="work-orders" element={<AuthGuard><WorkOrderListPage/></AuthGuard>} />

                <Route path="settings" element={<AuthGuard><SettingsPage/></AuthGuard>} />
              </Route>

              <Route path="login" element={<LoginPage/>} />
              <Route path="login-callback" element={<LoginCallbackPage/>} />
              <Route path="logout" element={<LogoutPage/>} />

              <Route path="external">
                <Route path="invoices">
                  <Route path=":estimateId" element={<EstimateItemizedPage/>}/>
                </Route>
                <Route path="estimates">
                  <Route path=":estimateId" element={<EstimateItemizedPage/>}/>
                </Route>
                <Route path="receipts">
                  <Route path=":receiptId" element={<EstimateItemizedPage/>}/>
                </Route>
              </Route>

              <Route path="*" element={<NoPagePage/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </UserSettingsProvider>
      </GoogleOAuthProvider>
      </LocationPermissionsProvider>
      </ToolbarProvider>
      </RealTimeProvider>
    </ThemeProvider>
  );
}

export default App;
