import React, {useState, useEffect, useRef, SetStateAction} from 'react';
import './EstimatePage.css';
import {
  Button,
  CircularProgress,
  Fab,
  Modal, SpeedDial, SpeedDialAction, styled, Toolbar, Typography, useMediaQuery,
} from "@mui/material";
import {Add as AddIcon, Delete, Fence, LocalShipping, LocationSearching, MyLocation, Nature, Park, TurnSlightRight} from "@mui/icons-material";
import chipperTruckImage from '../../assets/chipper_truck_image.png';
import treePin from '../../assets/tree_pin.png';
import personIcon from '../../assets/person_icon.png';
import {useNavigate, useParams} from "react-router-dom";
import {getEstimate, updateEstimate} from "./estimate.service";
import {createTree, updateTree} from "./estimate-tree.service";
import {EstimateTreeDto} from "../../shared/dtos/estimate-tree.dto";
import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import EstimateTree from "./EstimateTreeDetailComponent";
import {getApiKeys, getApiKeysSync, requireAuth} from "../../shared/services/auth.service";
import {enqueueSnackbar} from "notistack";
import {logError, logInfo} from "../../shared/services/logger.service";
import { Loader } from "@googlemaps/js-api-loader"
import MapImageIcon from "../../shared/components/MapImageIcon";
import {renderReactNode} from "../../shared/services/dom-helper.service";
import {createGate, updateGate} from "./estimate-gate.service";
import {EstimateGateDetailsDto, EstimateGateDto} from "../../shared/dtos/estimate-gate.dto";
import EstimateGateDetail from "./EstimateGateDetailComponent";
import {createAdditionalComponent, updateAdditionalComponent} from "./estimate-additional-component.service";
import {EstimateAdditionalComponentDto} from "../../shared/dtos/estimate-additional-component.dto";
import {AddEstimateDragPathDto, EstimateDragPathDto, LatLngDto, UpdateEstimateDragPathDto} from "../../shared/dtos/estimate-drag-path.dto";
import {createDragPath, deleteDragPath, updateDragPath} from "./estimate-drag-path.service";
import { MarkerInfo } from '../../shared/misc/marker-info.interface';
import {MarkableElement} from "../../shared/misc/markable-element";
import {syncMarkers} from "../../shared/services/maps-helper.service";
import EstimateInfoSidebar from "./EstimateInfoSidebar";
import EstimateDetail from "./EstimateDetailComponent";
import {useToolbar} from "../../components/ToolbarContext";
import {EstimateStatusUpdateMenu} from "../../shared/components/EstimateStatusUpdateMenu";
import {EstimateStatus, EstimateStatusType} from "../../shared/dtos/estimate.dto";
import {ExternalCompanyDto} from "../../shared/dtos/company.dto";
import {getCompany} from "../../shared/services/company.service";
import EstimateChipperTruckDetailComponent from "./EstimateChipperTruckDetailComponent";
import {findNearestChipperTruckWithDistance, getDragPathUpdate, getPathDistanceFeet} from "./estimate-page-helpers";

interface DragPathInfo {
  pathId: number,
  path: google.maps.Polyline,
}

const DEFAULT_ZOOM_LEVEL = 8;
const ICON_STANDARD_ZOOM_LEVEL = 21;
const POSITION_UPDATE_INTERVAL_MS = 10000;
const useInterval = (callback: () => void, delay: number) => {
  useEffect(() => {
    const intervalId = setInterval(callback, delay);

    return () => clearInterval(intervalId);
  }, [callback, delay]);
};

const EstimatePage = () => {
  const routeParams = useParams<Record<string, string>>();
  const estimateId = parseInt(routeParams["estimateId"]!);
  const navigate = useNavigate();
  const toolbarContext = useToolbar();
  const mapRef = useRef<HTMLDivElement>(null);

  const [estimate, setEstimate] = useState<EstimateWithComponentsDto>();
  const [changedEstimate, setChangedEstimate] = useState<EstimateWithComponentsDto>();
  const [company, setCompany] = useState<ExternalCompanyDto>();
  const [pageEssentialsLoaded, setPageEssentialsLoaded] = useState<boolean>(false);
  const [createItemIsLoading, setCreateItemIsLoading] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState({lat: 0, lng: 0})
  const [centerOnUser, setCenterOnUser] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapScale, setMapScale] = useState(1);
  const [userMarker, setUserMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  const [detailIsOpen, setDetailIsOpen] = useState<boolean>(false);

  const [selectedTree, setSelectedTree] = useState<EstimateTreeDto>();
  const [treeMarkers, setTreeMarkers] = useState<MarkerInfo[]>([]);
  const [treeIdToLocalIdMap, setTreeIdToLocalIdMap] = useState<{[key: number]: number}>({});

  const [selectedGate, setSelectedGate] = useState<EstimateGateDto>();
  const [gateMarkers, setGateMarkers] = useState<MarkerInfo[]>([]);
  const [gateIdToLocalIdMap, setGateIdToLocalIdMap] = useState<{[key: number]: number}>({});

  const [selectedChipperTruck, setSelectedChipperTruck] = useState<EstimateAdditionalComponentDto>();
  const [additionalComponentMarkers, setAdditionalComponentMarkers] = useState<MarkerInfo[]>([]);
  const [dragPathsObjects, setDragPathsObjects] = useState<DragPathInfo[]>([]);
  const [updateStatusMenuLastClick, setUpdateStatusMenuLastClick] = useState<React.MouseEvent<HTMLButtonElement> | null>(null);

  const changedEstimateRef = useRef(changedEstimate);
  useEffect(() => {
    changedEstimateRef.current = changedEstimate;
  }, [changedEstimate]);

  const getMaxZoom = (lat: number, lng: number) => {
    const latLng = new window.google.maps.LatLng(lat, lng);
    const maxZoomService = new window.google.maps.MaxZoomService();

    return new Promise<number>((resolve, reject) => {
      maxZoomService.getMaxZoomAtLatLng(latLng, response => {
        if (response.status === 'OK') {
          resolve(response.zoom);
        } else {
          logError('Max zoom not found for this location', {lat: estimate?.lat, lng: estimate?.lng})
          resolve(DEFAULT_ZOOM_LEVEL);
        }
      }).catch(e => {
        logError('Error getting max zoom', {lat: estimate?.lat, lng: estimate?.lng}, e)
        resolve(DEFAULT_ZOOM_LEVEL);
      });
    })
  }

  const buildElementIdToLocalIdMap = (elements: MarkableElement[]) => {
    const elementIdToLocalIdMap: {[key: number]: number} = {};
    elements
      .map(e => e.id)
      .sort((a, b) => a - b)
      .forEach((id, i) => {
        elementIdToLocalIdMap[id] = i + 1; // Start the ids at 1 for the user
      });
    return elementIdToLocalIdMap;
  }

  const bumpMarkerZIndexes = () => {
    // We want the markers to be drawn over the drag paths
    const markers = [...treeMarkers, ...gateMarkers, ...additionalComponentMarkers];
    markers.forEach(tm => {
      const currentIndex = tm.markerDomObject.zIndex ?? 0;
      tm.markerDomObject.zIndex = (currentIndex + 1) % 10;
    })
  }

  const onAdditionComponentMarkerClick = (additionalComponent: EstimateAdditionalComponentDto) => {
    if(additionalComponent.type === 'chipper') {
      setSelectedChipperTruck(additionalComponent);
    }
  }

  const syncTreeMarkers = () => {
    if(!map || !changedEstimate?.trees) return;

    const treeIdToLocalIdMap = buildElementIdToLocalIdMap(changedEstimate.trees);
    setTreeIdToLocalIdMap(treeIdToLocalIdMap);

    syncMarkers(
      document,
      map,
      mapScale,
      'tree',
      changedEstimate?.trees,
      treeMarkers,
      setTreeMarkers,
      tree => `${treeIdToLocalIdMap[tree.id]}`,
      tree => (<MapImageIcon id={`test-tree-marker-id-${tree.id}`} src={treePin} size={96} scale={mapScale} label={treeIdToLocalIdMap[tree.id].toString()} centerImage={true}/>),
      (tree) => {
        setSelectedTree(tree);
      },
      (tree, event) => {
        const position = {lat: event.latLng?.lat(), lng: event.latLng?.lng()};
        updateTreeLocation(tree, position.lat, position.lng);
      },
    )
  }

  const syncGateMarkers = () => {
    if(!map ||!changedEstimate?.gates) return;

    const gateIdToLocalIdMap = buildElementIdToLocalIdMap(changedEstimate.gates);
    setGateIdToLocalIdMap(gateIdToLocalIdMap);

    syncMarkers(
      document,
      map,
      mapScale,
      'gate',
      changedEstimate?.gates,
      gateMarkers,
      setGateMarkers,
      gate => `${gateIdToLocalIdMap[gate.id]}`,
      _ => (<Fence fontSize={'large'}/>),
      (gate) => {
        setSelectedGate(gate);
      },
      (gate, event) => {
        const position = {lat: event.latLng?.lat(), lng: event.latLng?.lng()};
        updateGateLocation(gate, position.lat, position.lng);
      },
    )
  }

  const syncAdditionalComponentMarkers = () => {
    if(!map ||!changedEstimate?.additionalComponents) return;

    syncMarkers(
      document,
      map,
      mapScale,
      'additional-component',
      changedEstimate?.additionalComponents,
      additionalComponentMarkers,
      setAdditionalComponentMarkers,
      additionalComponent => additionalComponent.id.toString(),
      _ => (<MapImageIcon src={chipperTruckImage} size={128} scale={mapScale} label={''} centerImage={true}/>),
      (element) => onAdditionComponentMarkerClick(element),
      (element, event) => {
        const position = {lat: event.latLng?.lat(), lng: event.latLng?.lng()};
        updateAdditionalComponentLocation(element, position.lat, position.lng);
      },
    )
  }

  const onZoomChanged = (map: google.maps.Map) => {
    const zoom = map.getZoom()!;
    const scale = Math.pow(2, zoom - ICON_STANDARD_ZOOM_LEVEL);
    setMapScale(scale);
  }

  const handleShowPreviewClick = () => {
    navigate(`/estimates/${estimateId}/itemized`);
  }

  const handleCompleteEstimateClick = () => {
    updateEstimate(navigate, estimateId, {status: EstimateStatus.EstimateCompleted}).then(() => {
      navigate(`/estimates/${estimateId}/itemized`);
    })
  }

  const toolbarActions = (status: EstimateStatusType) => (<div style={{display: 'flex', gap: '10px'}} >
    {status === EstimateStatus.EstimateInProgress && <Button variant='outlined' onClick={handleCompleteEstimateClick}>Complete</Button>}
    <Button variant='outlined' onClick={handleShowPreviewClick}>Preview</Button>
    <Button variant='outlined' onClick={setUpdateStatusMenuLastClick}>Update Status</Button>
  </div>)

  // Initial page load
  useEffect(() => {
    if(!mapRef.current) return;
    toolbarContext.initToolbar('EstimatePage', 'New Estimate');
    requireAuth(navigate).then(() => {
      getApiKeys(navigate)
        .then(() => Promise.all([getEstimate(navigate, estimateId), getCompany(navigate)]))
        .then(([e, c]) => {
          setEstimate(e);
          setCompany(c);
          setChangedEstimate(e);
          toolbarContext.initToolbar('EstimateItemizedPage', `${e.address} (${e.id})`, {}, () => toolbarActions(e.status));
          const loader = new Loader({
            apiKey: "AIzaSyBLw50vYSozTEviDIpu94K1KuwM4X_hDUM",
            version: "beta",
          });
          return loader.importLibrary('core').then(async () => {
            const { Map } = await loader.importLibrary("maps") as google.maps.MapsLibrary;
            await loader.importLibrary('marker');
            await loader.importLibrary('geometry');

            const center = centerOnUser && userLocation ? userLocation : {lat: e.lat, lng: e.lng};
            const maxZoom = await getMaxZoom(center.lat, center.lng);

            if(!mapRef.current) return; // On iOS, it seems the mapRef can get set to null in between the check at the top of this useEffect and here
            const map = new Map(mapRef.current!, {
              mapId: 'ESTIMATE_MAP',
              center: center,
              zoom: maxZoom,
              maxZoom: maxZoom,
              mapTypeId: 'satellite',
              tilt: 0,
              streetViewControl: false,
              zoomControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              scaleControl: false,
              rotateControl: false,
              clickableIcons: false,
            });
            map.addListener('zoom_changed', () => onZoomChanged(map));
            setMap(map);
            getUserLocationAndUpdate()
              .then(() => {
                setPageEssentialsLoaded(true);
                logInfo('Estimate page essentials loaded', {estimateId});
              }) // Immediately update user location, so the user marker is shown
              .catch(e => {
                logError('Error getting user location', {}, e);
                enqueueSnackbar(`Error getting user location: ${e}`, {variant: 'error', autoHideDuration: 5000});
              });
          });
        })
        .catch(e => {
          logError('Error fetching estimate', {}, e)
          enqueueSnackbar(`Error fetching estimate: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
    });
  }, [mapRef]);

  useEffect(() => {
    syncTreeMarkers();
  }, [map, changedEstimate?.trees])

  useEffect(() => {
    syncGateMarkers();
  }, [map, changedEstimate?.gates])

  useEffect(() => {
    syncAdditionalComponentMarkers();
  }, [map, changedEstimate?.additionalComponents]);

  useEffect(() => {
    if(!pageEssentialsLoaded) return;
    syncTreeMarkers();
    syncGateMarkers();
    syncAdditionalComponentMarkers();
  }, [mapScale]);


  // User marker updates
  useEffect(() => {
    if(!map) return;
    if(userMarker)
    {
      userMarker.map = null;
    }

    const content = renderReactNode(<MapImageIcon src={personIcon} size={64} centerImage={true}/>);
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: userLocation,
      map: map,
      content
    });
    setUserMarker(marker);
  }, [map, userLocation]);

  const onPathChanged_Callback = (pathId: number, path: google.maps.MVCArray<google.maps.LatLng>, changedPointIndex: number) => {
    const dragPathUpdate = getDragPathUpdate(changedEstimateRef.current!, path, pathId, changedPointIndex);
    updateDragPath(navigate, estimateId, pathId, dragPathUpdate)
      .then(() => {
        setChangedEstimate(ce => ({...ce!, dragPaths: ce!.dragPaths.map(d => d.id === pathId ? {...d, ...dragPathUpdate} : d)}));
        bumpMarkerZIndexes(); // This is to ensure the markers are drawn over the drag paths
      })
      .catch(e => {
        logError('Error updating drag path', {}, e);
        enqueueSnackbar(`Error updating drag path: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });
  };

  // Drag path updates
  useEffect(() => {
    if(!map) return;
    const pathsToRemove = dragPathsObjects.filter(dp => !changedEstimate?.dragPaths.some(d => d.id === dp.pathId));
    const pathsToKeep = dragPathsObjects.filter(dp => changedEstimate?.dragPaths.some(d => d.id === dp.pathId));
    const pathsNotOnMap = changedEstimate?.dragPaths.filter(d => !dragPathsObjects.some(dp => dp.pathId === d.id)) ?? [];

    const pathsToAppend = pathsNotOnMap.map(dp => {
      const path = new google.maps.Polyline({
        path: dp.points.map(p => new google.maps.LatLng(p.lat, p.lng)),
        geodesic: true,
        strokeColor: 'red',
        strokeOpacity: 1.0,
        editable: true,
        strokeWeight: 2,
        map: map,
      });
      google.maps.event.addListener(path.getPath(), 'insert_at', (newPointIndex: number) => onPathChanged_Callback(dp.id, path.getPath(), newPointIndex));
      google.maps.event.addListener(path.getPath(), 'set_at', (setPointIndex: number) => onPathChanged_Callback(dp.id, path.getPath(), setPointIndex));
      return {pathId: dp.id, path};
    });
    const pathUpdatePromises = pathsToKeep.map(async pathToKeep => {
      const estimatePath = changedEstimate!.dragPaths.find(d => d.id === pathToKeep.pathId)!;
      const estimatePathPoints = estimatePath.points.map(p => new google.maps.LatLng(p.lat, p.lng));
      const pathsMatch = estimatePathPoints.length == pathToKeep.path.getPath().getLength() &&
        pathToKeep.path.getPath().getArray().every((p, i) => p.lat() === estimatePathPoints[i].lat() && p.lng() === estimatePathPoints[i].lng());
      if(!pathsMatch) {
        const newPath = new google.maps.MVCArray(estimatePathPoints);
        google.maps.event.addListener(newPath, 'insert_at', (newPointIndex: number) => onPathChanged_Callback(estimatePath.id, newPath, newPointIndex));
        google.maps.event.addListener(newPath, 'set_at', (setPointIndex: number) => onPathChanged_Callback(estimatePath.id, newPath, setPointIndex));
        pathToKeep.path.setPath(newPath);
        await updateDragPath(navigate, estimateId, pathToKeep.pathId, {
          points: estimatePath.points,
          distanceFeet: estimatePath.distanceFeet
        });
        setDragPathsObjects(prevDragPaths => prevDragPaths.map(dp => dp.pathId === pathToKeep.pathId ? pathToKeep : dp));
      }
    });
    Promise.all(pathUpdatePromises)
      .then(() => {
        pathsToRemove.forEach(p => p.path.setMap(null));
        setDragPathsObjects([...pathsToKeep, ...pathsToAppend]);
        bumpMarkerZIndexes(); // This is to ensure the markers are drawn over the drag paths
      })
      .catch(e => {
        logError('Error updating drag paths', {}, e);
        enqueueSnackbar(`Error updating drag paths: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }, [map, changedEstimate?.dragPaths]);

  // Center on user
  useEffect(() => {
    if (!map) return;

    if(centerOnUser) {
      map.setCenter(userLocation);
    }
  }, [userLocation, centerOnUser]);

  function getUserLocationAndUpdate(): Promise<{lat: number, lng: number}> {
    return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      pos => {
        const newLocation = {lat: pos.coords.latitude, lng: pos.coords.longitude};
        setUserLocation(newLocation);
        resolve(newLocation);
      },
      error => {
        enqueueSnackbar('Error fetching location', {variant: 'error', autoHideDuration: 5000})
        logError("Error getting user's location", {error}, new Error(error.message))
        resolve(userLocation);
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      });
    });
  }

  function continuousUserLocationUpdate() {
    return getUserLocationAndUpdate();
  }

  useInterval(continuousUserLocationUpdate, POSITION_UPDATE_INTERVAL_MS);

  const toggleCenterOnUser = () => {
    if(centerOnUser)
    {
      enqueueSnackbar('Centering on user disabled, map is unlocked', {variant: 'info', autoHideDuration: 5000})
    } else {
      enqueueSnackbar('Centering on user enabled, map will follow your location', {variant: 'info', autoHideDuration: 5000})
    }
    setCenterOnUser(!centerOnUser);
  }

  const createDragPaths = async (chipperId: number, chipperLocation: {lat: number, lng: number}, trees: EstimateTreeDto[]) => {
    const createPathPromises = trees.map(tree => {
      const points = [{lat: tree.lat, lng: tree.lng}, chipperLocation];
      const distanceFeet = getPathDistanceFeet(points);
      const dragPath: AddEstimateDragPathDto = {
        estimateId: estimateId,
        treeId: tree.id,
        chipperTruckId: chipperId,
        points: [{lat: tree.lat, lng: tree.lng}, chipperLocation],
        distanceFeet
      };
      return createDragPath(navigate, estimateId, dragPath);
    });
    await Promise.all(createPathPromises)
      .then(createdPaths => {
        setChangedEstimate(ce => ({...ce!, dragPaths: [...(ce?.dragPaths ?? []), ...createdPaths]}))
      })
      .catch(e => {
        logError('Error creating drag paths', {}, e);
        enqueueSnackbar(`Error creating drag paths: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }

  const addTree = async () => {
    setCreateItemIsLoading(true);
    // const treeLocation = {lat: userLocation.lat -.00003, lng: userLocation.lng};
    const treeLocation = {lat: estimate!.lat, lng: estimate!.lng};
    await createTree(navigate, estimateId, {lat: treeLocation.lat, lng: treeLocation.lng})
      .then(async createdTree => {
        setChangedEstimate(ce => ({...ce!, trees: [...ce!.trees, createdTree]}));
        setSelectedTree(createdTree);
        const chipper = findNearestChipperTruckWithDistance(changedEstimate!, createdTree.lat, createdTree.lng)?.chipperTruck;
        if(chipper) {
          await createDragPaths(chipper.id, {lat: chipper.lat, lng: chipper.lng}, [createdTree]);
        }
      })
      .catch(e => {
        logError('Error creating tree', {}, e)
        enqueueSnackbar(`Error creating tree: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setCreateItemIsLoading(false));
  }

  const addGate = async () => {
    setCreateItemIsLoading(true);
    // const gateLocation = {lat: userLocation.lat -.00003, lng: userLocation.lng};
    const gateLocation = {lat: estimate!.lat, lng: estimate!.lng};
    await createGate(navigate, estimateId, {lat: gateLocation.lat, lng: gateLocation.lng})
      .then(createdGate => {
        setChangedEstimate(ce => ({...ce!, gates: [...ce!.gates, createdGate]}));
        setSelectedGate(createdGate);
      })
      .catch(e => {
        logError('Error creating gate', {}, e)
        enqueueSnackbar(`Error creating gate: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setCreateItemIsLoading(false));
  }

  const addChipperTruck = async () => {
    setCreateItemIsLoading(true);
    const location = {lat: estimate!.lat, lng: estimate!.lng};
    await createAdditionalComponent(navigate, estimateId, {lat: location.lat, lng: location.lng, type: 'chipper'})
      .then(async createdChipperTruck => {
        setChangedEstimate(ce => {
          return {...ce!, additionalComponents: [...ce!.additionalComponents, createdChipperTruck]}
        });
        const isFirstChipper = !changedEstimate!.additionalComponents.some(ac => ac.type === 'chipper' && ac.id !== createdChipperTruck.id);
        const estimateHasDragPaths = changedEstimate!.dragPaths.length > 0
        if(isFirstChipper && !estimateHasDragPaths) {
          await createDragPaths(createdChipperTruck.id, location, changedEstimate!.trees);
        }
      })
      .catch(e => {
        logError('Error creating chipper', {}, e)
        enqueueSnackbar(`Error creating chipper: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setCreateItemIsLoading(false));
  }

  const updateTreeLocation = async (tree: EstimateTreeDto, lat: number | undefined, lng: number | undefined) => {
    if(!lat || !lng) return;

    const updatedTree = {
      ...tree,
      lat,
      lng
    };
    const updateDto = {
      ...updatedTree,
      treeId: tree.id,
      imageIds: tree.images?.map(i => i.id) ?? [],
      updatedBy: tree.createdBy,
    }
    setChangedEstimate(ce => ({...ce!, trees: ce!.trees.map(t => t.id === tree.id ? updatedTree : t)}));
    await updateTree(navigate, estimateId, tree.id, updateDto)
      .then(() => {
        updateDragPathStart(tree.id, {lat, lng});
      })
      .catch(e => {
        logError('Error moving tree', {}, e)
        enqueueSnackbar(`Error moving tree, please refresh: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }

  const updateGateLocation = async (gate: EstimateGateDetailsDto, lat: number | undefined, lng: number | undefined) => {
    if(!lat || !lng) return;

    const updatedGate = {
      ...gate,
      lat,
      lng
    };
    const updateDto = {
      ...updatedGate,
      gateId: gate.id,
      imageIds: gate.images?.map(i => i.id) ?? [],
      updatedBy: gate.createdBy,
    }
    setChangedEstimate(ce => ({...ce!, gates: ce!.gates.map(t => t.id === gate.id ? updatedGate : t)}));
    await updateGate(navigate, estimateId, gate.id, updateDto)
      .catch(e => {
        logError('Error moving gate', {}, e)
        enqueueSnackbar(`Error moving gate, please refresh: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }

  const updateDragPathStart = (treeId: number, newTreeLocation: {lat: number, lng: number}) => {
    setChangedEstimate(ce =>  {
      const pathToUpdate = ce!.dragPaths.find(dp => dp.treeId === treeId);
      if(!pathToUpdate) {
        return ce;
      }
      const points = [newTreeLocation, ...pathToUpdate.points.slice(1, pathToUpdate.points.length)];
      const distanceFeet = getPathDistanceFeet(points);
      const updatedPath = {...pathToUpdate, points, distanceFeet};
      return {...ce!, dragPaths: ce!.dragPaths.map(dp => dp.id === updatedPath.id ? updatedPath : dp)}
    });
  }

  const updateDragPathsEnds = (chipperId: number, newChipperLocation: {lat: number, lng: number}) => {
    // First, update the state with the new paths
    setChangedEstimate(ce => {
      const pathsToUpdate = ce!.dragPaths.filter(dp => dp.chipperTruckId === chipperId);
      const pathsNotToUpdate = ce!.dragPaths.filter(dp => dp.chipperTruckId !== chipperId);
      const updatedPaths = pathsToUpdate.map(dp => {
        const points = [...dp.points.slice(0, dp.points.length - 1), newChipperLocation];
        const distanceFeet = getPathDistanceFeet(points);
        const updatedPath = {...dp, points, distanceFeet};
        return updatedPath;
      });

      return {...ce!, dragPaths: [...pathsNotToUpdate, ...updatedPaths]};
    });

    setChangedEstimate(ce => {
      const pathsToUpdate = ce!.dragPaths.filter(dp => dp.chipperTruckId === chipperId);
      const updatePromises = pathsToUpdate.map(dp => updateDragPath(navigate, estimateId, dp.id, dp));

      Promise.all(updatePromises)
        .catch(error => {
          console.error("Failed to update drag paths:", error);
        });

      // Return the state unchanged - we already updated it in the previous call
      return ce;
    });
  };

  // const updateDragPathsEnds = async (chipperId: number, newChipperLocation: {lat: number, lng: number}) => {
  //   setChangedEstimate(ce =>  {
  //     const pathsToUpdate = ce!.dragPaths.filter(dp => dp.chipperTruckId === chipperId);
  //     const pathsNotToUpdate = ce!.dragPaths.filter(dp => dp.chipperTruckId !== chipperId);
  //     const updatedPaths = pathsToUpdate.map(dp => {
  //       const points = [...dp.points.slice(0, dp.points.length - 1), newChipperLocation];
  //       const distanceFeet = getPathDistanceFeet(points);
  //       const updatedPath = {...dp, points, distanceFeet};
  //       return updatedPath;
  //     });
  //     const updatePromises = updatedPaths.map(dp => updateDragPath(navigate, estimateId, dp.id, dp));
  //     await Promise.all(updatePromises);
  //     return {...ce!, dragPaths: [...pathsNotToUpdate, ...updatedPaths]}
  //   });
  // }

  const updateAdditionalComponentLocation = async (additionalComponent: EstimateAdditionalComponentDto, lat: number | undefined, lng: number | undefined) => {
    if(!lat || !lng) return;

    const updatedAdditionalComponent = {
      ...additionalComponent,
      lat,
      lng
    };
    const updateDto = {
      ...updatedAdditionalComponent,
      gateId: additionalComponent.id,
      imageIds: additionalComponent.images?.map(i => i.id) ?? [],
      updatedBy: additionalComponent.createdBy,
    }
    setChangedEstimate(ce => ({...ce!, additionalComponents: ce!.additionalComponents.map(t => t.id === additionalComponent.id ? updatedAdditionalComponent : t)}));
    await updateAdditionalComponent(navigate, estimateId, additionalComponent.id, updateDto)
      .then(component => {
        if(component.type === 'chipper') {
          updateDragPathsEnds(component.id, {lat, lng});
        }
      })
      .catch(e => {
        logError('Error moving chipper', {}, e)
        enqueueSnackbar(`Error moving chipper, please refresh: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }

  const handleGateDelete = (deletedGate: EstimateGateDto) => {
    setChangedEstimate(ce => ({...ce!, gates: ce!.gates.filter(t => t.id !== deletedGate.id)}));
    setSelectedGate(undefined);
  }

  const handleNewTree = async (updatedTree: EstimateTreeDto) => {
    setChangedEstimate({...changedEstimate!, trees: changedEstimate!.trees.map(t => t.id === updatedTree.id ? updatedTree : t)});
    await addTree();
  }

  const handleNewGate = async (updatedTree: EstimateTreeDto) => {
    setChangedEstimate({...changedEstimate!, trees: changedEstimate!.trees.map(t => t.id === updatedTree.id ? updatedTree : t)});
    clearTreeSelection();
    await addGate();
  }

  const handleFinishEstimate = (updatedTree: EstimateTreeDto) => {
    setChangedEstimate(ce => ({...ce!, trees: ce!.trees.map(t => t.id === updatedTree.id ? updatedTree : t)}));
    setSelectedTree(undefined); // Close the modal
  }

  const handleTreeDelete = (deletedTree: EstimateTreeDto) => {
    const dragPath = changedEstimate?.dragPaths.find(dp => dp.treeId === deletedTree.id);
    const dragPathDeletionPromise = dragPath ? deleteDragPath(navigate, estimateId, dragPath.id) : Promise.resolve();
    dragPathDeletionPromise
      .then(() => {
        setChangedEstimate(ce => (
          {...ce!,
            trees: ce!.trees.filter(t => t.id !== deletedTree.id),
            dragPaths: ce!.dragPaths.filter(dp => dp.id !== dragPath?.id ?? -1)
          }));
      })
      .catch(e => {
        logError('Error deleting drag path', {}, e)
        enqueueSnackbar(`Error deleting drag path: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => {
        setSelectedTree(undefined);
      })
  }

  const handleNewTreeFromGate = async (updateGate: EstimateGateDetailsDto) => {
    setChangedEstimate({...changedEstimate!, gates: changedEstimate!.gates.map(t => t.id === updateGate.id ? updateGate : t)});
    setSelectedGate(undefined); // Close the gate modal
    await addTree();
  }

  const handleFinishEstimateFromGate = async (updateGate: EstimateGateDetailsDto) => {
    setChangedEstimate({...changedEstimate!, gates: changedEstimate!.gates.map(t => t.id === updateGate.id ? updateGate : t)});
    setSelectedGate(undefined); // Close the modal
  }

  const clearTreeSelection = () => {
    setSelectedTree(undefined);
  }

  const clearGateSelection = () => {
    setSelectedGate(undefined);
  }

  const handleTreeClick = (treeId: number) => {
    const tree = changedEstimate?.trees.find(t => t.id === treeId);
    setSelectedTree(tree);
  }

  const handleEditDetailsClick = () => {
    setDetailIsOpen(true);
  }

  const handleEstimateDetailsSaved = (estimate: EstimateWithComponentsDto) => {
    setChangedEstimate(estimate);
    setDetailIsOpen(false);
  }

  const handleDetailClose = () => {
    setDetailIsOpen(false);
  }

  const handleChipperTruckDelete = async (deletedChipperTruck: EstimateAdditionalComponentDto) => {
    const attachedDragPaths = changedEstimate?.dragPaths.filter(dp => dp.chipperTruckId === deletedChipperTruck.id) ?? [];
    const nextChipperTruck = changedEstimate?.additionalComponents.find(ac => ac.type === 'chipper' && ac.id !== deletedChipperTruck.id);
    let newPaths: EstimateDragPathDto[];

    if(nextChipperTruck) {
      const updatedPaths = attachedDragPaths.map(dp => {
        const allButFinalPoint = dp.points.slice(0, dp.points.length - 1);
        const newPoints = [...allButFinalPoint, {lat: nextChipperTruck.lat, lng: nextChipperTruck.lng}];
        return {
          ...dp,
          chipperTruckId: nextChipperTruck.id,
          points: newPoints,
          distanceFeet: getPathDistanceFeet(newPoints)
        }
      });
      const updatePathsPromises = updatedPaths.map(up => updateDragPath(navigate, estimateId, up.id, up));
      await Promise.all(updatePathsPromises);

      newPaths = changedEstimate?.dragPaths.map(dp => updatedPaths.find(up => up.id === dp.id) ?? dp) ?? []
    } else {
      const deletePathsPromises = attachedDragPaths.map(dp => deleteDragPath(navigate, estimateId, dp.id));
      await Promise.all(deletePathsPromises);
      newPaths = changedEstimate?.dragPaths.filter(dp => !attachedDragPaths.some(adp => adp.id === dp.id)) ?? [];
    }
    setChangedEstimate(ce => ({
      ...ce!,
      additionalComponents: ce!.additionalComponents.filter(t => t.id !== deletedChipperTruck.id),
      dragPaths: newPaths
    }))
    setSelectedChipperTruck(undefined);
  }

  const handleChipperTruckDetailCancel = () => {
    setSelectedChipperTruck(undefined);
  }

  return (
    <div className="full-page">
      <div style={{position: 'relative', width: '100%', height: '100%'}}>
        <div id="map" ref={mapRef}></div>
        <EstimateStatusUpdateMenu estimateId={estimateId} lastButtonClickEvent={updateStatusMenuLastClick}/>
        <div className="estimate-control-overlay">
          <EstimateInfoSidebar estimate={changedEstimate} treeIdToLocalIdMap={treeIdToLocalIdMap} onTreeClick={handleTreeClick}
                               onEditDetailsClick={handleEditDetailsClick} startOpen={true} />
        </div>
        <div className="estimate-control-overlay bottom-controls-container">
          <div className="bottom-right-element-control-container ">
            <div className="center-on-user-fab-container">
              <Fab className="overlaid-control" color="info" onClick={toggleCenterOnUser} aria-label="add">
                {centerOnUser ? <MyLocation fontSize="large"/> : <LocationSearching fontSize="large"/>}
              </Fab>
            </div>
            <div className="add-element-speed-dial-container">
              <SpeedDial className="overlaid-control" color="primary" ariaLabel={"Add"} icon={createItemIsLoading ? <CircularProgress color="secondary"/> : <AddIcon fontSize="large"/>} direction={"up"}>
                <SpeedDialAction onClick={addTree} className="overlaid-control" color="primary" key={'addTree'} icon={<Park/>} tooltipTitle={'Add Tree'}/>
                <SpeedDialAction onClick={addGate} className="overlaid-control" color="primary" key={'addGate'} icon={<Fence/>} tooltipTitle={'Add Gate'}/>
                <SpeedDialAction onClick={addChipperTruck} className="overlaid-control" color="primary" key={'addAdditionalComponent'} icon={<LocalShipping/>} tooltipTitle={'Add Chipper Truck'}/>
              </SpeedDial>
            </div>
          </div>
        </div>
      </div>
      <Modal open={!!selectedTree} onClose={clearTreeSelection}>
        <EstimateTree
          tree={selectedTree!}
          treeLocalId={treeIdToLocalIdMap[selectedTree?.id ?? -1]}
          company={company!}
          onNewTree={handleNewTree}
          onNewGate={handleNewGate}
          onFinishEstimate={handleFinishEstimate}
          onTreeDelete={() => handleTreeDelete(selectedTree!)}/>
      </Modal>
      <Modal open={!!selectedGate} onClose={clearGateSelection}>
        <EstimateGateDetail
          estimateId={estimateId}
          estimateTrees={changedEstimate?.trees ?? []}
          treeLocalIdMap={treeIdToLocalIdMap}
          gateId={selectedGate?.id ?? -1}
          gateLocalId={gateIdToLocalIdMap[selectedGate?.id ?? -1]}
          onNewTree={handleNewTreeFromGate}
          onFinishEstimate={handleFinishEstimateFromGate}
          onGateDelete={() => handleGateDelete(selectedGate!)}/>
      </Modal>
      <Modal open={detailIsOpen} onClose={handleDetailClose}>
        <EstimateDetail estimate={changedEstimate!} onEstimateSaved={handleEstimateDetailsSaved}/>
      </Modal>
      <Modal open={!!selectedChipperTruck}>
        <EstimateChipperTruckDetailComponent chipperTruck={selectedChipperTruck!} onDelete={() => handleChipperTruckDelete(selectedChipperTruck!)} onCancel={handleChipperTruckDetailCancel}/>
      </Modal>
    </div>
  );
};



export default EstimatePage;
