import React, {ChangeEvent} from "react";
import {Button} from "@mui/material";
import {compressImage} from "../shared/misc/image-helpers";
import {logError} from "../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";

export interface AddImagesProps {
  onImagesAdded: (images: File[]) => void;
  label?: string;
  imageQuality?: number;
}
const DEFAULT_IMAGE_QUALITY = 0.75;

export const AddImages = (props: AddImagesProps) => {
  const handleImagesUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const compressionPromises = Array.from(files).map(file =>
      compressImage(file, props.imageQuality ?? DEFAULT_IMAGE_QUALITY)
    );

    await Promise.all(compressionPromises)
      .catch((e) => {
        logError('Error  images', {}, e);
        enqueueSnackbar(`Error uploading images: ${e}`, {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return []
      })
      .then(compressedFiles => {
        props.onImagesAdded(compressedFiles);
      })
  }

  return (
    <Button variant="contained" component="label">
      {props.label ?? 'Add Picture'}
      <input type="file" onChange={handleImagesUpload} hidden multiple/>
    </Button>
  );
}