export const DefaultUserSettings: UserSettingsDto = {
  imageUploadQuality: 0.8,
};

export interface UserSettingsDto {
  imageUploadQuality: number;
}

export interface UpdateUserSettingsDto {
  imageUploadQuality?: number;
}