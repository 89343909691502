import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {DefaultUserSettings, UserSettingsDto } from '../pages/settings/dtos/settings.dto';
import {getUserSettings, updateUserSettings} from "../pages/settings/settings.service";

interface UserSettingsContextType {
  settings: UserSettingsDto;
  loading: boolean;
  error: Error | null;
  refreshSettings: () => Promise<void>;
  updateSettings: (newSettings: Partial<UserSettingsDto>) => Promise<void>;
}

const UserSettingsContext = createContext<UserSettingsContextType | undefined>(undefined);

interface UserSettingsProviderProps {
  children: ReactNode;
}

export const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState<UserSettingsDto>(DefaultUserSettings);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const refreshSettings = async (): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      const freshSettings = await getUserSettings();
      setSettings(freshSettings);
      localStorage.setItem('userSettings', JSON.stringify(freshSettings));
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      // If fetch fails, try to use cached settings
      const cachedSettings = localStorage.getItem('userSettings');
      if (cachedSettings) {
        setSettings(JSON.parse(cachedSettings));
      }
    } finally {
      setLoading(false);
    }
  };

  const updateSettings = async (newSettings: Partial<UserSettingsDto>): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      const updatedSettings = await updateUserSettings(newSettings);
      setSettings(updatedSettings);
      localStorage.setItem('userSettings', JSON.stringify(updatedSettings));
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // First try to load from localStorage to avoid delays
    const cachedSettings = localStorage.getItem('userSettings');
    if (cachedSettings) {
      setSettings(JSON.parse(cachedSettings));
      setLoading(false);
    }

    refreshSettings()
      .catch((err) => {
        console.log('Failed to refresh settings:', err);
        setLoading(false);
      });
  }, []);

  return (
    <UserSettingsContext.Provider value={{settings, loading, error, refreshSettings, updateSettings}}>
      {children}
    </UserSettingsContext.Provider>
  );
};

// Custom hook to use the settings context
export const useUserSettings = (): UserSettingsContextType => {
  const context = useContext(UserSettingsContext);
  if (context === undefined) {
    throw new Error('useUserSettings must be used within a UserSettingsProvider');
  }
  return context;
};