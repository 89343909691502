import {getUserTokenOrRedirect, getUserTokenOrThrow} from "../../shared/services/auth.service";
import {UpdateUserSettingsDto, UserSettingsDto} from "./dtos/settings.dto";

export async function getUserSettings(): Promise<UserSettingsDto> {
  const token = getUserTokenOrThrow();
  return await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/user-settings`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch user settings: ${response.statusText}`);
      }
      return await response.json() as UserSettingsDto
    });
}

export async function updateUserSettings(settings: UpdateUserSettingsDto): Promise<UserSettingsDto> {
  const token = getUserTokenOrThrow();
  return await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/user-settings`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(settings)
    })
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to update user settings integrations: ${response.statusText}`);
      }
      return await response.json() as UserSettingsDto
    });
}